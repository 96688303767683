<template>
  <div class="cphs">
    <div class="bg_block">
      <div class="brief">
        <div class="b_tit">赛虎科技</div>
        <div class="b_tit">MNSS安全综合服务平台</div>
        <div class="b_subtit">
          以保障风险管控效果为目标，提供体系化、常态化的安全综合服务，构建7x24小时全维度的安全服务能力，实现快速处置的业务闭环持续不断保障客户网络安全。
        </div>
        <el-button type="primary" size="mini" @click="$myutils.jumpto('apply')">申请试用</el-button>
      </div>
    </div>
    <div class="first_block">
      <div class="img_bg"></div>
      <div class="main_tit">
        <div>云 平 台 服 务</div>
        <div class="line"></div>
        <div>SERVICE CONTENT</div>
      </div>
      <div class="the_cnt" data-aos="fade-up">
        <div class="one_item" v-for="i in bgImg1" :key="i" :style="`background: url(${require('../assets/comper/' + i.name + '-bg.jpg')}) no-repeat;`">
          <img :src="getImg(i.name)" alt="" height="40">
          <div class="sub_tit">{{i.name}}</div>
          <div class="line"></div>
          <div class="detail">{{i.cnt}}</div>
        </div>
      </div>
    </div>
    <div class="second_block">
      <div class="img_bg"></div>
      <div class="main_tit">
        <div>综 合 安 全 终 端</div>
        <div class="line"></div>
        <div>SERVICE CONTENT</div>
      </div>
      <div class="the_cnt" data-aos="fade-up">
        <div class="one_item" v-for="i in bgImg2" :key="i.name" :style="`background: url(${require('../assets/comper/' + i.name + '-bg.jpg')}) no-repeat;`">
          <img :src="getImg(i.name)" alt="" height="40">
          <div class="sub_tit">{{i.name}}</div>
          <div class="line"></div>
          <div class="detail">{{i.cnt}}</div>
        </div>
      </div>
    </div>
    <div class="third_block">
      <div class="img_bg"></div>
      <div class="main_tit">
        <div>服 务 价 值</div>
        <div class="line"></div>
        <div>SERVICE VALUE</div>
      </div>
      <div class="the_cnt">
        <div class="one_item" data-aos="fade-up" data-aos-delay="100">
          <img src="../assets/comper/2分钟应急响应.png" alt="" height="50">
          <div class="sub_tit">
            <span>2</span>分钟应急响应
          </div>
        </div>
        <div class="one_item" data-aos="fade-up" data-aos-delay="200">
          <img src="../assets/comper/3层技术防护体系.png" alt="" height="50">
          <div class="sub_tit">
            <span>3</span>层技术防护体系
          </div>
        </div>
        <div class="one_item" data-aos="fade-up" data-aos-delay="300">
          <img src="../assets/comper/4组专业服务梯队.png" alt="" height="50">
          <div class="sub_tit">
            <span>4</span>组专业服务梯队
          </div>
        </div>
        <div class="one_item" data-aos="fade-up" data-aos-delay="400">
          <img src="../assets/comper/5类安全终端设备.png" alt="" height="50">
          <div class="sub_tit">
            <span>5</span>类安全终端设备
          </div>
        </div>
        <div class="one_item" data-aos="fade-up" data-aos-delay="500">
          <img src="../assets/comper/36个核心功能模块.png" alt="" height="50">
          <div class="sub_tit">
            <span>36</span>个核心功能模块
          </div>
        </div>
        <div class="one_item" data-aos="fade-up" data-aos-delay="600">
          <img src="../assets/comper/724全时服务能力.png" alt="" height="50">
          <div class="sub_tit">
            <span>7x24</span>全时服务能力
          </div>
        </div>
      </div>
    </div>
    <div class="eighth_block">
      <div class="img_bg"></div>
      <div class="main_tit">
        <div>服务流程</div>
        <div class="line"></div>
        <div>DAILY SATETY MONITORING OPERATION</div>
      </div>
      <div class="the_cnt" data-aos="zoom-in-right">
        <img class="flow_pc" src="../assets/comper/服务流程.png" alt="" width="1080px">
        <img class="flow_m" src="../assets/comper/服务流程_m.png" alt="">
      </div>
    </div>
    <div class="fourth_block">
      <div class="img_bg"></div>
      <div class="main_tit">
        <div>日常安全监测运营</div>
        <div class="line"></div>
        <div>DAILY SATETY MONITORING OPERATION</div>
      </div>
      <div class="the_cnt" data-aos="zoom-in-right">
        <img class="flow_pc" src="../assets/comper/日常安全监测运营.png" alt="" width="1080px">
        <img class="flow_m" src="../assets/comper/日常安全监测运营_m.png" alt="">
      </div>
    </div>
    <div class="fifth_block">
      <div class="img_bg"></div>
      <div class="main_tit">
        <div>网络安全事件应急处置</div>
        <div class="line"></div>
        <div>EMERGENCY RESPONSE OF NETWORK SECURITY EVENTS</div>
      </div>
      <div class="the_cnt" data-aos="zoom-in-left">
        <img class="flow_pc" src="../assets/comper/网络安全事件应急处置.png" alt="" width="1080px">
        <img class="flow_m" src="../assets/comper/网络安全事件应急处置_m.png" alt="">
      </div>
    </div>
    <div class="sixth_block">
      <div class="img_bg"></div>
      <div class="main_tit">
        <div>重要时期保障、攻防演习值守</div>
        <div class="line"></div>
        <div>SECURITY、ATTACK AND DEFENSE EXERCISES ON DUTY DURING IMPORTANT PERIODS</div>
      </div>
      <div class="the_cnt" data-aos="zoom-in-down">
        <img class="flow_pc" src="../assets/comper/重要时期保障.png" alt="" width="1080px">
        <img class="flow_m" src="../assets/comper/重要时期保障_m.png" alt="">
      </div>
    </div>
    <div class="seventh_block">
      <div class="img_bg"></div>
      <div class="main_tit">
        <div>客户收益</div>
        <div class="line"></div>
        <div>CUSTOMER INCOME</div>
      </div>
      <div class="the_cnt" data-aos="zoom-out-down">
        <img v-for="i in 4" :key="i" :src="getImg(i)" width="200">
      </div>
    </div>
  </div>
</template>

 <script>
 export default {
  data(){
    return{
      bgImg1:[
        {name:'安全监测',cnt:'7*24全时段监测，包括安全漏洞、违规内容检测、非法链接监测、可用性监测等，监测频率可达到秒级响应，保证业务安全稳定运行。'},
        {name:'态势管理',cnt:'支持安全资产情报、漏洞监测结果、篡改监测结果、本地监测结果、威胁分析等数据实时动态展示，随时掌握网络安全状态。'},
        {name:'工单支撑',cnt:'提供在线交互式应答支持、全方位专家答疑、技术支撑、安全咨询。'},
        {name:'风险处置',cnt:'安全风险云端在线处置、技术协助、事件跟踪、结果动态更新，确保安全问题不过夜，事件处理高效闭环。'}
      ],
      bgImg2:[{name:'安全扫描系统',cnt:'10W+规则特征库、5W+指纹规则库，自动构建资产结构，关联资产威胁情报，自动生成漏洞扫描报告，一件漏洞复测确保风险得到有效处置。'},
        {name:'威胁分析系统',cnt:'轻量化部署，5000+动态规则库精细化处置识别匹配，威胁分析速率>6000条/s，秒级威胁感知，智能与人工多重研判。'},
        {name:'应急支撑系统',cnt:'全面兼容各类设备与权限控制，应急重保双重模式适应各类场景。多重认证措施、技术操作全程记录确保安全接入。'}],
    }
  },
  methods:{
    getImg(item) {
      return require('../assets/comper/'+`${item}`+'.png');
    },
  },
  mounted(){
    this.$aos.init({
      offset:200,
      duration: 600,
      easing: 'ease-in-sine',
      disable: 'phone'
    });
  }
 }
 </script>

 <style lang="scss" scoped>
  .cphs{
    color: #fff;
    .bg_block{
      width: 100%;
      background: url(../assets/comper/bg.png) no-repeat;
      background-size:100% 100%;
      .brief{
        color: $light-base;
        padding: 200px 0 220px 12vw;
        width: 50vw;
        font-size: 1.2vw;
        .b_tit{
          font-size: 3vw;
          font-weight: bold;
        }
        .b_subtit{          
          margin: 30px 0 60px 0;
        }
      }
    }
    // 复用类
    .main_tit{
      text-align: center;
      margin-bottom: 40px;
      div{
        &:first-child{
          font-size: 2.2vw;
          font-weight: bold;
        }
        &:last-child{
          color: $gray-base;
          font-size: 1vw;
        }        
      }
      .line{
        width: 30vw;
        height: 1px;
        background-color: #fff;
        margin: 6px auto;
      }
    }
    .the_cnt{
      img{z-index:99;}
    }
    .one_item{
      padding: 30px;
      width: 240px;
      height: 300px;
      text-align: center;
      background-size: 100% 100%!important;
      z-index: 99;
      .sub_tit{
        margin: 8px 0;
      }
      .line{
        width: 100%;
        height: 1px;
        background-color: #fff;
        margin: 6px auto;
      }
    }
    .img_bg{
      position: absolute;
      left:0px;
      top:0px;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
    //单独模块
    .first_block,.second_block,.third_block,.fourth_block,.fifth_block,.sixth_block,.seventh_block,.eighth_block{
      background:#0b1b33;
      background-size:100% 100%;
      padding: 100px 0;
      position: relative;
      .flow_m{
        display: none;
        width: 100%;
      }
    }
    .first_block,.fourth_block,.seventh_block{
      .img_bg{
        background: url('../assets/第一部分.png');
        background-size: contain;
      }
    }
    .second_block,.fifth_block,.eighth_block{
      .img_bg{
        background: url('../assets/第二部分.png');
        background-size: contain;
      }
    }
    .third_block,.sixth_block{
      .img_bg{
        background: url('../assets/第三部分.png');
        background-size: contain;
      }
    }
    .first_block,.second_block{
      .the_cnt{
        width: 75%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        .detail{
          line-height: 24px;
          text-align: left;
        }
      }
    }
    // .first_block{
    //   background: url(../assets/comper/bg.png) no-repeat;
    // }
    .third_block{
      .the_cnt{
        width: 80%;
        display: flex;
        justify-content: space-around;
        margin: 0 auto;
        flex-wrap: wrap;
        .one_item{
          width: 30%;
          height: 200px;
          .sub_tit{
            font-size: 1.2vw;
            span{
              font-size: 1.6vw;
              color: #f48d06;
              font-weight: 900;
              margin-right: 6px;
            }
          }
        }
      }
    }
    .fourth_block,.fifth_block,.sixth_block,.eighth_block{
      .the_cnt{
        text-align: center;
      }
      .line{
        width: 520px;
      }
    }
    .seventh_block{
      .the_cnt{
        width: 80%;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
      }
    }
    @media screen and (max-width: 1080px){
      .main_tit{
        margin-bottom: 20px;
        div{
          &:first-child{
            font-size: 18px;
          }
          &:last-child{
            font-size: 10px;
          }        
        }
        .line{
          width: 40vw;
        }
      }
      .one_item{
        margin-bottom: 10px;
      }
      .bg_block{
        .brief{
          width: 100%;
          padding: 40px 30px;
          font-size: 14px;
          .b_tit{
            font-size: 28px;
          }
          .b_subtit{
            margin: 20px 0px;
          }
        }
      }
      .first_block,.second_block,.third_block,.fourth_block,.fifth_block,.sixth_block,.seventh_block,.eighth_block{
        padding: 40px 30px;
        .flow_m{
          display: block;
        }
        .flow_pc{
          display: none;
        }
      }
      .first_block,.second_block{
        .the_cnt{
          width: 100%;
          flex-wrap: wrap;
          // flex-direction: column;
          justify-content: space-around;
          align-items: center;
        }
      }
      .third_block{
        .the_cnt{
          width: 100%;
          .one_item{
            width: 48%;
            max-width: 260px;
            height: 120px;
            padding: 10px;
            img{
              height: 60px;
            }
            .sub_tit{
              font-size: 12px;
              span{
                font-size: 14px;
              }
            }
          }
        }
      }
      .seventh_block{
        .the_cnt{
          width: 100%;
          flex-wrap: wrap;
        }
        img{
          margin-bottom: 10px;
          width: 48%;
          max-width: 200px;
          z-index: 99;
        }
      }
    }

  }
 </style>
